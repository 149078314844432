import 'isomorphic-fetch'

import { uploadEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'

export const fetchPresignedUrl = async params => {
  const { method, url } = uploadEndpoint.presignedUrl
  const idToken = getIdToken()
  console.log(url, params)
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify(params)
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))

  return apiResponse
}

export const uploadFileToS3WithPresignedUrl = async (url, file) => {
  const apiResponse = await fetch(url, {
    method: 'PUT',
    body: file
  })
    .catch(error => ({
      message: error.message,
      success: false
    }))

  return apiResponse
}
