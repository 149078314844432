import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Image, Input, Space, Spin } from 'antd'
import PropTypes from 'prop-types'
import { fetchPresignedUrl, uploadFileToS3WithPresignedUrl } from '~actions'

const LogoUploader = ({
  handleUpload,
  logoUrl
}) => {
  const [uploadStatus, setUploadStatus] = useState(false)
  const handleImageUpload = async (file) => {
    if (!file) {
      return
    }
    try {
      console.log('&&&')
      setUploadStatus(true)
      const fileName = file?.name.split('.')
      const formattedFile = `${fileName[0]}_${new Date().getTime()}.${fileName[1]}`
      const response = await fetchPresignedUrl({
        fileName: `event-engine/${formattedFile}`,
        fileType: file.type
      })
      console.log('response', response)
      const { presignedPutUrl, url } = response
      const fileUploadResponse = await uploadFileToS3WithPresignedUrl(presignedPutUrl, file)
      console.log(fileUploadResponse)
      setUploadStatus(false)
      console.log(url)
      handleUpload(url)
    } catch (error) {
      console.error(error)
      setUploadStatus(false)
    }
  }

  const handleImageDelete = (event) => {
    handleUpload('')
    event.preventDefault()
  }

  console.log(uploadStatus, logoUrl)
  return (<Space direction='vertical' size={12}>{uploadStatus && <Spin indicator={<LoadingOutlined spin />} size="small" />}
    <Input onChange={(event) => handleImageUpload(event.target.files[0])} type='file' />
    { logoUrl &&
      <>
        <Image
          src={logoUrl}
        />
        <Button className="cloudinary-delete" onClick={handleImageDelete} type='link'> Clear </Button>
      </>
    }
  </Space>
  )
}

LogoUploader.propTypes = {
  handleUpload: PropTypes.func,
  logoUrl: PropTypes.string
}

export default LogoUploader
